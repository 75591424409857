
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Search {
  max-width: 768px;
  min-width: 300px;
  transition: $easing-standard $duration-short;

  @include mq($breakpoint-mobileNav) {
    box-shadow: 0 0 16px 0 rgba($color-primary-black, 0.4);
    border-radius: 8px;
  }

  &.isOpen {
    transition: $easing-standard $duration-short;
    @include mq($breakpoint-desktop) {
      transform: scale(1.1);
      max-width: 800px;
    }

    @include mq($breakpoint-desktopMedium) {
      transform: scale(1.2);
      max-width: 1000px;
    }
  }
}

.SearchButton {
  color: $color-primary-teal;
  background-color: $color-primary-white;
  border: 1px solid $color-primary-grey-lighter;
  border-left: 0;

  @include mq(0, $breakpoint-mobileNav - 1) {
    border-radius: 0;
  }
}

.SearchSuggestionsWrapper {
  top: 50px;
  box-shadow: 0 8px 16px 0 rgba($color-primary-black, 0.4);
  border-radius: 0 0 $radius-small $radius-small;

  @include mq($breakpoint-desktopLarge) {
    top: 75px;
  }
}

.SearchIcon {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
  @include mq($breakpoint-desktopLarge) {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
}

.SearchFieldWrapper {
  height: spacing(7);
  z-index: 4;
  @include mq($breakpoint-desktopLarge) {
    height: spacing(10);
  }
}

.SearchField {
  border-right: 0;
  @include mq(0, $breakpoint-mobileNav - 1) {
    border-radius: 0;
  }
}

.Overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $color-primary-grey;
  opacity: 0;
  top: 0;
  left: 0;
  transition: $duration-short $easing-standard;
  pointer-events: none;
  &.visibleOverlay {
    opacity: 0.5;
    transition: $duration-short $easing-standard;
    pointer-events: all;
  }
}

.ViewAll {
  display: block;
  padding: spacing(2);
  color: $color-primary-teal;
  font-weight: bold;
}
