
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.IconWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 1180px;
  margin: 100px auto 0;
}

.FamilyIcon.left {
  @include mq($breakpoint-tablet) {
    width: 161px !important;
    height: 90px !important;
    line-height: 90px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 323px !important;
    height: 180px !important;
    line-height: 180px !important;
  }
}

.FamilyIcon.right {
  transform: scaleX(-1);
  margin-right: 10%;

  @include mq($breakpoint-tablet) {
    width: 116px !important;
    height: 68px !important;
    line-height: 68px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 233px !important;
    height: 136px !important;
    line-height: 136px !important;
  }
}

.Branding {
  min-width: 16rem;
}
