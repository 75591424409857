
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '~backline-normalize/src/backline-normalize';

html {
  scroll-behavior: smooth;
}

body {
  font-size: spacing(2); // 16
  line-height: spacing(3); // 24
  font-weight: normal;
  font-family: $font-body;

  @include mq(0, $breakpoint-tablet) {
    &.modal-open {
      overflow: hidden;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:focus,
  &:hover {
    color: inherit;
    outline: 0;
  }

  &:active {
    color: inherit;
  }
}
