
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .RatingColor {}

@each $key, $value in $color-rating-list {
  .color-#{$key} {
    color: $value !important;
  }

  .background-color-#{$key} {
    background-color: $value !important;
    color: $color-primary-white;

    * {
      color: $color-primary-white !important;
    }
  }

  .border-#{$key} {
    border-color: $value !important;
    * {
      border-color: $value !important;
    }
  }

  .fill-#{$key} {
    circle,
    path {
      fill: $value !important;
    }
  }
}
