
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Header {
  @include mq($breakpoint-desktop) {
    flex-wrap: nowrap !important;
  }

  &.isActive {
    flex-wrap: wrap;
  }
}

.LogoWrapper {
  @include mq($breakpoint-desktop) {
    width: 300px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 480px;
  }
}

.NavigationActions {
  width: auto;
  z-index: 1;

  @include mq($breakpoint-mobileNav) {
    width: 125px !important;
  }
}

.Logo {
  width: 175px !important;

  @include mq($breakpoint-mobileNav) {
    width: 125px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 260px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 302px !important;
  }
}

.Triangle {
  position: absolute;
  z-index: -1;
  top: -30px;
  left: -50vw;
  width: 120vw !important;

  @include mq($breakpoint-mobileLandscape) {
    top: -60px;
  }

  @include mq($breakpoint-tablet) {
    left: -20vw;
    width: 100vw !important;
  }

  @include mq($breakpoint-mobileNav) {
    left: -7vw;
    top: -70px;
    width: 90vw !important;
  }

  @include mq($breakpoint-desktop) {
    left: 0;
    top: -70px;
    width: 70vw !important;
  }

  @include mq($breakpoint-desktopMedium) {
    top: -100px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 66vw !important;
    top: -120px;
  }
}

.SearchToggle {
  @include mq($breakpoint-mobileNav) {
    display: none;
  }

  .isDark & {
    svg path {
      fill: $color-primary-white;
    }
  }
}

.HeaderToggle {
  .isDark & {
    svg path {
      fill: $color-primary-white;
    }
  }
}

.SearchWrapper {
  flex-grow: 2;
  padding: 0 spacing(6.5) 0 spacing(2);
  z-index: 1;

  @include mq($breakpoint-mobileNav) {
    padding: spacing(2) spacing(3) spacing(2) spacing(6.5);
    display: block !important;
  }

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(1.5);
  }

  @include mq(1000px) {
    padding: spacing(3) spacing(4);
  }

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) spacing(4);
  }

  @include mq($breakpoint-desktopLarge) {
    padding: spacing(4) 0;
  }

  @include mq(0, $breakpoint-mobileNav - 1) {
    order: 2;
    padding: 0;
  }
}
