
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AgeRatingCarousel {
  position: relative;
  min-height: 33px;
}

.AgeRating {
  position: absolute;
  will-change: opacity;
}
