
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.SearchField {
  border: 1px solid $color-primary-grey;
}

.SearchButton {
  border: 1px solid $color-primary-grey;
  border-left: none;
  color: $color-primary-white;
  background-color: $color-primary-teal;
}

.SearchSuggestionsWrapper {
  border: 1px solid $color-primary-grey;
  top: 43px;

  @include mq($breakpoint-desktopMedium) {
    top: 54px;
  }

  @include mq($breakpoint-desktopLarge) {
    top: 81px;
  }
}

.SearchFieldWrapper {
  height: spacing(6);

  @include mq($breakpoint-desktopMedium) {
    height: spacing(8);
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(11);
  }
}
