
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.RatingsDetail {
  background-color: $color-common-white;
  text-align: left;
  padding: spacing(4) spacing(1);
  color: $color-primary-grey;
  border-bottom: 1px solid $color-primary-grey-lighter;

  @include mq($breakpoint-mobileNav) {
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    padding: spacing(8) 0 spacing(6);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
    clip-path: polygon(0 0, 100% 0%, 100% 150%, 0% 150%);
    border-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      top: spacing(2);
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-primary-grey-lighter;
      z-index: 7;
    }
  }

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(12) 0 spacing(10);
  }
}

.Wrapper {
  margin-bottom: spacing(2.5);
  padding-right: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq($breakpoint-mobileNav) {
    display: block;
    margin-bottom: spacing(3);
    padding-right: 0;
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(6);
  }
}

.Rating {
  margin-right: spacing(2);
  @include mq($breakpoint-mobileNav) {
    display: none;
  }
}

.Title {
  @include mq(0, $breakpoint-mobileNav - 1) {
    flex-grow: 1;
  }
}

.Body {
  margin-bottom: spacing(3);

  @include mq($breakpoint-mobileNav) {
    margin-bottom: spacing(4);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(8);
  }
}

.Link {
  font-weight: 700;
  font-size: spacing(1.75);
  color: $color-primary-teal;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  @include mq($breakpoint-mobileNav) {
    margin-bottom: spacing(5);
    font-size: spacing(2);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(8);
    font-size: spacing(3);
  }
}

.Close {
  display: none;
  position: absolute;
  top: spacing(2);
  right: spacing(0);

  @include mq($breakpoint-mobileNav) {
    display: block;
  }

  @include mq($breakpoint-desktopMedium) {
    top: spacing(4);
    right: spacing(2);
  }

  &:hover,
  &:focus {
    color: $color-primary-black;
  }
}
