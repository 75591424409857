
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ScrollTopButton {
  position: fixed;
  bottom: spacing(2);
  right: spacing(2);
  z-index: 100;
  transition: transform, 0.2s ease-in-out, bottom 0.2s ease-in-out;
  height: 80px;
  width: 80px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.16);
  border-radius: 100px;

  @include mq($breakpoint-tablet) {
    height: 90px;
    width: 90px;
  }

  & > * {
    height: 100% !important;
    width: 100% !important;

    svg {
      transform: scale(-2);
      transform-origin: 50% 50%;
    }
  }
}

.Hidden {
  transform: translateY(100%);
  bottom: 0;
}
