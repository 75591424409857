
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ListItem {
  display: flex;
  align-items: center;
  padding: spacing(2) spacing(2.5) - 1;
  width: 100%;
  @include mq($breakpoint-tabletLandscape) {
    padding: spacing(2) spacing(4) - 1;
  }
}

.Wrapper {
  background-color: $color-primary-grey-lightest;

  &:last-child.bottomRadius {
    border-radius: 0 0 $radius-small $radius-small;
  }
}

.ListItemTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 spacing(2) 0 spacing(2);
}

.ListItemTitleWrapper {
  display: flex;
  align-items: center;
}

.ListItemText {
  font-weight: 500;
  text-align: left;

  @include mq($breakpoint-desktopLarge) {
    font-size: 40px;
  }
}

.ListItemType {
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 0 0 rem(16);
  color: $color-primary-teal;
}

.SearchIcon {
  margin: 0 spacing(1);
  width: 24px !important;
  height: 24px !important;

  svg path {
    fill: $color-primary-black;
  }

  @include mq($breakpoint-tabletLandscape) {
    width: 36px !important;
    height: 36px !important;
  }
}

.RatingIcon {
  width: 39px !important;
  height: 34.6667px !important;
  @include mq($breakpoint-tabletLandscape) {
    width: 55px !important;
    height: 48.8889px !important;
  }

  &.iconsLarge {
    @include mq($breakpoint-desktopLarge) {
      width: 108px !important;
      height: 108px !important;
    }
  }
}

.active,
.active .ListItemType {
  background-color: $color-primary-teal;
  color: $color-primary-white;

  &:hover,
  &:hover .ListItemType {
    color: $color-primary-white;
  }

  .SearchIcon {
    svg path {
      fill: $color-primary-white;
    }
  }
}
