
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Theme {
  font-weight: bold;
}

.green {
  color: $color-highlight-green;
  border-color: $color-highlight-green;
}

.yellow {
  color: $color-highlight-yellow;
  border-color: $color-highlight-yellow;
}

.orange {
  color: $color-highlight-orange;
  border-color: $color-highlight-orange;
}

.red {
  color: $color-highlight-red;
  border-color: $color-highlight-red;
}

.pink {
  color: $color-highlight-pink;
  border-color: $color-highlight-pink;
}

.blue {
  color: $color-highlight-blue;
  border-color: $color-highlight-blue;
}

.jade {
  color: $color-highlight-jade;
  border-color: $color-highlight-jade;
}
