
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Navigation {
  @include mq($breakpoint-desktop) {
    width: 300px;
    text-align: right;
    display: block !important;
    padding: 0;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 480px;
  }

  &.isActive {
    padding-top: spacing(1);
  }
}

.NavigationSurround {
  padding: spacing(2);
  display: block;

  @include mq($breakpoint-desktop) {
    display: inline-block;
  }
}

.NavigationLink {
  color: $color-primary-teal;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(1.75);
    margin-left: spacing(1.5);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(1.95);
    margin-left: spacing(2);
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(2.5);
    margin-left: spacing(5);
  }
}
