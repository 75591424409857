#mc_embed_signup {
  clear: left;
  font: 14px 'proxima-nova', sans-serif;
}
#mc-embedded-subscribe-form .mc_fieldset {
  border: none;
  min-height: 0;
  padding-bottom: 0;
  padding-top: 2%;
}

#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 0 10px 3%;
}

#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}

#mc_embed_signup .mc-field-group {
  clear: left;
  position: relative;
  width: 96%;
  padding-bottom: 2%;
  min-height: 50px;
}
#mc_embed_signup .size1of2 {
  clear: none;
  float: left;
  display: inline-block;
  width: 46%;
  margin-right: 4%;
}
* html #mc_embed_signup .size1of2 {
  margin-right: 2%; /* Fix for IE6 double margins. */
}
#mc_embed_signup .mc-field-group label {
  display: block;
  margin: 3px 0;
}
#mc_embed_signup .mc-field-group input {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
  color: #374a52;
}
#mc_embed_signup .mc-field-group select {
  display: inline-block;
  width: 99%;
  padding: 5px 0;
  margin-bottom: 2px;
}

#mc_embed_signup .datefield,
#mc_embed_signup .phonefield-us {
  padding: 5px 0;
}
#mc_embed_signup .datefield input,
#mc_embed_signup .phonefield-us input {
  display: inline;
  width: 60px;
  margin: 0 2px;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px 0 2px 0;
}
#mc_embed_signup .phonefield-us .phonearea input,
#mc_embed_signup .phonefield-us .phonedetail1 input {
  width: 40px;
}
#mc_embed_signup .datefield .monthfield input,
#mc_embed_signup .datefield .dayfield input {
  width: 30px;
}
#mc_embed_signup .datefield label,
#mc_embed_signup .phonefield-us label {
  display: none;
}

#mc_embed_signup .indicates-required {
  text-align: right;
  font-size: 11px;
  margin-right: 4%;
}
#mc_embed_signup .asterisk {
  color: #e85c41;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}
#mc_embed_signup .clear {
  clear: both;
}

#mc_embed_signup .mc-field-group.input-group ul {
  margin: 0;
  padding: 5px 0;
  list-style: none;
}
#mc_embed_signup .mc-field-group.input-group ul li {
  display: block;
  padding: 3px 0;
  margin: 0;
}
#mc_embed_signup .mc-field-group.input-group label {
  display: inline;
}
#mc_embed_signup .mc-field-group.input-group input {
  display: inline;
  width: auto;
  border: none;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0 0.5em 0 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}

#mc-embedded-subscribe {
  clear: both;
  width: auto;
  display: block;
  margin: 1em 0 1em 0;
}
#mc_embed_signup #num-subscribers {
  font-size: 1.1em;
}
#mc_embed_signup #num-subscribers span {
  padding: 0.5em;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  font-weight: bold;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  display: inline-block;
  margin: 2px 0 2px;
  padding: 5px 10px;
  background-color: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  color: #e85c41;
}

#mergeRow-gdpr {
  margin-top: 2%;
}

#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
  border: 2px solid #e85c41;
}

.checkbox-label {
  margin-left: 16px;
}

.isDark > .mce_inline_error {
  background-color: #374a52 !important;
}

.isDark > label,
.isDark div,
.isDark p,
.isDark span {
  color: #ffffff;
}

.isDark > a:active,
.isDark > a:focus,
.isDark > a:hover {
  color: #ffffff;
}
