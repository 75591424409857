
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.HeaderWrapper {
  box-shadow: 0 0 16px 0 rgba($color-primary-black, 0.4);
}

.Header {
  background-color: $color-common-white;
}

.LogoWrapper {
  @include mq($breakpoint-desktop) {
    width: 300px;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 400px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 500px;
  }
}

.Logo {
  width: 145px !important;

  @include mq($breakpoint-mobileLandscape) {
    width: 155px !important;
  }

  @include mq($breakpoint-mobileNav) {
    width: 124px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 195px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 233px !important;
  }
}

.NavigationActions {
  @include mq($breakpoint-mobileNav) {
    width: 124px;
  }
}

.HeaderToggle {
  min-width: spacing(5);
  @include mq($breakpoint-mobileLandscape) {
    min-width: spacing(6);
  }
}

.SearchWrapper {
  background-color: $color-primary-white;

  @include mq($breakpoint-desktop) {
    display: block !important;

    &::before {
      content: '';
      top: 0;
      left: spacing(-4);
      right: spacing(-4);
      position: absolute;
      height: 1px;
      background-color: $color-primary-grey-lighter;
    }
  }
}
