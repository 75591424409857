
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.RatingsList {
  padding: spacing(2) 0;

  @include mq(0, $breakpoint-mobileNav - 1) {
    display: none;
    padding: spacing(0.5) 0;
  }
}

.Rating {
  padding: 0 spacing(0.5);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @include mq($breakpoint-desktopMedium) {
    padding: 0 spacing(0.5);
  }

  @include mq($breakpoint-desktopLarge + 50) {
    padding: 0 spacing(1);
  }

  &:focus {
    outline: 0;
  }
}

.RatingIcon {
  position: relative;
  height: 47px !important;
  width: 53px !important;

  @include mq($breakpoint-desktop, $breakpoint-desktop + 100) {
    height: 35px !important;
    width: 40px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    height: 56px !important;
    width: 62.5px !important;
  }

  @include mq($breakpoint-desktopLarge + 150) {
    height: 88px !important;
    width: 99px !important;
  }

  svg {
    height: 100% !important;
    width: auto !important;
  }

  .Rating:hover &,
  .Rating:focus &,
  .Rating.isActive & {
    &::after {
      content: '';
      position: absolute;
      bottom: spacing(-2.125);
      height: 4px;
      left: 0;
      right: 0;
      z-index: 4;
      background-color: currentColor;

      @include mq($breakpoint-desktop) {
        bottom: spacing(-2.625);
      }
    }
  }
}

.DetailsWrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  @include mq(0, $breakpoint-mobileNav - 1) {
    overflow: auto;
    height: calc(100vh - 57px);
    top: 57px;
  }
}

// $color-list: (
//   'color-green': $color-highlight-green,
//   'color-yellow': $color-highlight-yellow,
//   'color-orange': $color-highlight-orange,
//   'color-pink': $color-highlight-pink,
//   'color-red:' $color-highlight-red,
//   'color-teal': $color-primary-teal
// );

// @each $key, $value in $color-list {
//   .#{$key}::after {
//     color: $value;
//   }
// }
