.HeaderWrapper {
  position: relative;
  z-index: 4;
}

.Header {
  position: relative;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  @include mq($breakpoint-desktop) {
    padding: 0 spacing(4);
  }
}

.SiteTitle {
  text-align: left;
  padding-left: spacing(3);
  display: flex;
  align-items: center;

  @include mq($breakpoint-desktop) {
    padding-left: 0;
  }

  h1 {
    display: inline-block;
  }
}

.LogoWrapper {
  @include mq($breakpoint-mobileNav, $breakpoint-desktop - 1) {
    display: none;
  }
}

.TabletLogoWrapper {
  display: none;

  @include mq($breakpoint-mobileNav, $breakpoint-desktop - 1) {
    display: block;
  }
}

.Logo {
  height: auto !important;
  vertical-align: middle;

  svg {
    width: 100%;
    height: auto !important;
  }
}

.CarouselWrapper {
  display: inline-block;
  margin-right: spacing(1.5);
  width: spacing(4);
  vertical-align: middle;

  @include mq($breakpoint-mobileNav) {
    display: none;
  }
}

.DetailsWrapper {
  display: none;

  @include mq(0, $breakpoint-mobileNav - 1) {
    display: block;
    overflow: auto;
    height: calc(100vh - 57px);
    top: 57px;
  }
}

.NavigationActions {
  text-align: right;
  min-height: spacing(7);
  padding: spacing(0.5) spacing(1) spacing(0.5) 0;

  @include mq($breakpoint-mobileNav) {
    width: 124px;
  }

  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.Toggle {
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.SearchWrapper {
  padding: spacing(2);
  position: relative;
  z-index: -2;
  display: none;
  &.isActive {
    display: block;
  }

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(2.5) 0;
  }

  @include mq($breakpoint-desktopLarge) {
    padding: spacing(3) 0;
  }
}

.HeaderToggle {
  &:hover {
    svg path {
      transition: $easing-standard $duration-short;
      fill: $color-primary-teal;
    }
  }
}

// $color-list: (
//   green: $color-highlight-green,
//   yellow: $color-highlight-yellow,
//   orange: $color-highlight-orange,
//   pink: $color-highlight-pink,
//   red: $color-highlight-red,
//   teal: $color-primary-teal
// );

// @each $key, $value in $color-list {
//   .#{$key}::after {
//     color: $value;
//   }
// }
