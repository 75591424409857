
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Navigation {
  background-color: $color-primary-grey-lightest;
  @include mq($breakpoint-desktop) {
    width: 300px;
    background-color: $color-primary-white;
    text-align: right;
    display: block !important;
    padding: 0;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 400px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 500px;
  }
}

.NavigationLink {
  &:last-of-type {
    margin-bottom: spacing(6);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(1.75);
    margin-left: spacing(1.25);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: spacing(1.5);
    }
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(2.25);
    margin-left: spacing(1.5);

    &:last-of-type {
      margin-right: spacing(2.5);
    }
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(3);
  }
}
